import "./button.scss";
import { act, JSX } from '../../jsx-act'

interface ButtonProps extends Record<string, unknown> {
    dataId?: string,
    variant?: 'primary' | 'secondary' | 'link'
    /** @deprecated Use `children` instead */
    text?: string,
    children?: JSX.Child;
    props?: {[x:string]: string},
    /** @deprecated use `onClick` instead */
    events?: any //TODO define better type
    onClick?: (event: MouseEvent) => void
}

export default ({ children, dataId, variant = 'secondary', text, events = {}, props = {}, ...rest }: ButtonProps) => {
    return (
        <button
            tabindex="0"
            type="submit"
            data-id={dataId}
            {...rest}
            {...props}
            onClick={events.onclick || rest.onClick}
            class={`awsccc-u-btn awsccc-u-btn-${variant}`}>
            <span>{text || children}</span>
        </button>
    )
};
