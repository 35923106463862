import {
  ERROR_MESSAGE_MODAL_DISMISS_BTN_ID,
  ERROR_MESSAGE_MODAL_ID,
} from "../../constants/ux-constants";
import UXDefinitions from "../../definitions/ux-definitions";
import { act } from "../../jsx-act";
import Button from "../utilities/button";
import Modal from "../utilities/modal";
import SpaceBetween from "../utilities/space-between";

interface ErrorMessageModalProps {
  localizedText: UXDefinitions.ErrorMessageLocalization;
  handleDismissClick: UXDefinitions.HandleDismissClick;
  darkModeEnabled?: boolean;
}

export default ({
  localizedText,
  handleDismissClick,
  darkModeEnabled,
}: ErrorMessageModalProps) => {
  function dismissClicked() {
    handleDismissClick();
  }

  return (
    <Modal
      id={ERROR_MESSAGE_MODAL_ID}
      darkModeEnabled={darkModeEnabled}
      size="large"
      onDismiss={dismissClicked}
      header={<span id="awsccc-em-title">{localizedText.header}</span>}
      footer={
        <SpaceBetween id="awsccc-em-f-c" direction="horizontal" size="xs">
          <Button
            dataId={ERROR_MESSAGE_MODAL_DISMISS_BTN_ID}
            variant="primary"
            onClick={dismissClicked}
            props={{ "aria-label": localizedText["button-dismiss-aria-label"] }}
          >
            {localizedText["button-dismiss"]}
          </Button>
        </SpaceBetween>
      }
    >
      <div id="awsccc-em-modalBody">
        <p id="awsccc-emm-paragraph">{localizedText.paragraph}</p>
      </div>
    </Modal>
  );
};
