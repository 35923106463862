import { DEFAULT_COOKIE } from "../constants/cookie-constants";
import { localizationDictionary, localizationRtl } from "../constants/localization-dictionary";
import { APP_ID, BANNER_ID, CCBA_MODAL_ID, CONTAINER_ID, CUSTOMIZE_ID, ERROR_MESSAGE_MODAL_ID, ERROR_MESSAGE_MODAL_TABTRAP_ID, TABTRAP_ID } from "../constants/ux-constants";
import { getConsentCookie as getConsentCookieCore, getId, ValidationFields } from "../cookie";
import CookieDefinitions, { COOKIE_CATEGORIES, ESSENTIAL } from "../definitions/cookie-definitions";
import UXDefinitions from "../definitions/ux-definitions";
import { render, act } from "../jsx-act";
import { Log } from "../logger";
import domReady from "../ready";
import ConsentBanner from "./consent-banner/consent-banner";
import { CcbaSelector } from "./consent-ccba/ccba-selector";
import ConsentSelector from "./consent-selector/consent-selector";
import ErrorMessageModal from "./consent-selector/error-message-modal";
import "./ux-components.scss";
import LogSources = UXDefinitions.LogSources;
import { closeModal, openModal } from "./utilities/modal-controls";

interface ShortbreadUiOptions {
    parent: HTMLElement | undefined,
    language: UXDefinitions.LanguageCodesNormalized,
    onSaveConsent: (consent: CookieDefinitions.ConsentCookie) => void,
    getConsentCookie: () => ReturnType<typeof getConsentCookieCore>,
    log: Log,
    onModalClose?: () => void,
    hasConsoleNavFooter?: boolean,
    runtimeEnvironment?: UXDefinitions.RuntimeEnvironment,
    domain: string | undefined,
    handleValidation: (fields: ValidationFields, source: string) => boolean
}

export function isChecked(category: CookieDefinitions.CookieCategory): boolean {
    return document
        .querySelector(`label[data-id=awsccc-u-cb-${category}-label] input`)!
        .getAttribute("checked") === "";
}

function renderUi(options: ShortbreadUiOptions) {

    const localizedText: UXDefinitions.LocalizationSet = localizationDictionary[options.language]
    const isRtl = localizationRtl.indexOf(options.language) > -1;
    const conditionalClasses = isRtl ? "awsccc-Rtl" : "";

    function _getBannerEl(): HTMLElement {
        return document.querySelector<HTMLElement>(`div[data-id=${BANNER_ID}]`)!
    }

    function setCheckboxStatus(category: CookieDefinitions.CookieCategory, on: boolean) {
        const labelEl = document.querySelector(`label[data-id=awsccc-u-cb-${category}-label]`);
        const classList = labelEl!.classList;
        const inputEl = labelEl!.querySelector("input")

        if (on) {
            inputEl!.setAttribute("checked", "")
            classList.add("awsccc-u-cb-checkbox-active");
        } else {
            classList.remove("awsccc-u-cb-checkbox-active");
            inputEl!.removeAttribute("checked")
        }
        inputEl!.setAttribute("aria-checked", `${on}`);
    }

    const handleCheckboxToggle: UXDefinitions.HandleCheckboxToggle = ({ event, category }) => {
        if (event.target.getAttribute("type") === "checkbox" || event.target.getAttribute("class") === "awsccc-cs-s-title" || event.target.getAttribute("class")?.includes("awsccc-u-cb-label")) {
            setCheckboxStatus(category, !isChecked(category));
        }
    }

    const handleSaveClick = (context: 'acceptAll' | 'customize') => (cookie: CookieDefinitions.ConsentCookie, source) => {
        const firstTabEl = _getBannerEl().querySelector<HTMLElement>(`div[data-id=awsccc-cb-tabstart]`)
        _getBannerEl().style.display = 'none';
        firstTabEl!.setAttribute("tabindex", "-1")

        if (context === 'customize') {
            closeConsentSelector();
        }

        if (!options.handleValidation({ domain: options.domain}, 'saveBtnClick')) {
            showErrorMessage();
            return;
        }
        options.onSaveConsent(cookie);
        options.log("info")(context, { detail: "Save Consent Clicked", source, cookie: options.getConsentCookie(), uuid: getId() });
    }

    const handleCancelClick = (context: string) => (source) => {
        options.log("info")(context, { detail: "Customize Modal Cancel Button Clicked", source, cookie: options.getConsentCookie(), uuid: getId() });
        closeConsentSelector();
    }

    // for error message modal
    const handleDismissClick = () => {
        closeErrorMessageModal();
    }

    const showBanner: UXDefinitions.HandleShowBanner = () => {
        const firstTabEl = _getBannerEl().querySelector<HTMLElement>(`div[data-id=awsccc-cb-tabstart]`)
        _getBannerEl().style.display = 'block';
        firstTabEl!.setAttribute("tabindex", "0")
        firstTabEl!.focus({
            preventScroll: true
        });
    }

    const getCookie = () => {
        return options.getConsentCookie() || DEFAULT_COOKIE;
    }

    const updateCheckboxStatusFromCookie = () => {
        const cookie = getCookie();
        COOKIE_CATEGORIES.filter(c => c !== ESSENTIAL).forEach(category => {
            setCheckboxStatus(category, cookie[category]);
        });
    }

    const showConsentSelector: UXDefinitions.HandleShowConsent = (source) => {
        updateCheckboxStatusFromCookie();
        openModal(CUSTOMIZE_ID);

        options.log("info")("customizeCookies", { detail: "Customize Consent Clicked", source, cookie: options.getConsentCookie(), uuid: getId() });
    };

    const handleCcbaSaveClick = (context: string) => (cookie: CookieDefinitions.ConsentCookie, source) => {
        if (!options.handleValidation({ domain: options.domain}, 'saveBtnClick')) {
            showErrorMessage();
            return;
        }
        options.onSaveConsent(cookie);
        closeCcbaPreferenceModal();
        options.log("info")(context, { detail: "CCBA Preference Saved", source, cookie: options.getConsentCookie(), uuid: getId() });
    }

    const handleCcbaCancelClick = (context: string) => (source) => {
        options.log("info")(context, { detail: "CCBA Modal Dismissed", source, cookie: options.getConsentCookie(), uuid: getId() });
        closeCcbaPreferenceModal();
    }

    const showCcbaPreferenceModal = (source) => {
        openModal(CCBA_MODAL_ID);

        options.log("info")("ccbaModalOpened", { detail: "CCBA Modal Opened", source, cookie: options.getConsentCookie(), uuid: getId() });
    };

    const showErrorMessage: UXDefinitions.HandleShowErrorMessage = () => {
        openModal(ERROR_MESSAGE_MODAL_ID);
    }

    const closeConsentSelector = () => {
        closeModal(CUSTOMIZE_ID);

        /**
         * Accessibility - If modal is closed but banner is still open
         * which occurs when a user cancels, focus back onto the banner
         */
        if (_getBannerEl().style.display === "block") {
            const firstTabEl = _getBannerEl().querySelector<HTMLElement>(`div[data-id=awsccc-cb-tabstart]`)
            firstTabEl!.focus({
                preventScroll: true
            });
        }
        /**
         * Accessibility - optional function that can be called after closing the modal.
         * Can be used to focus on another element that opened the modal.
         */
        if (options.onModalClose) {
            options.onModalClose()
        }
    };

    const closeCcbaPreferenceModal = () => {
        closeModal(CCBA_MODAL_ID);
    }

    const closeErrorMessageModal = () => {
        closeModal(ERROR_MESSAGE_MODAL_ID);
    };


    domReady(() => {
        if (document.querySelector(`#${CONTAINER_ID}`)) {
            // The Shortbread components have already been placed in the DOM, no need to add them again
            return;
        }
        render(options.parent || document.body,
            <div id={CONTAINER_ID}>
                <div id={APP_ID} class={conditionalClasses}>
                   <ConsentBanner
                        showConsentSelector={showConsentSelector}
                        handleSaveClick={handleSaveClick("acceptAll")}
                        localizedText={localizedText.consentBanner}
                        hasConsoleNavFooter={options.hasConsoleNavFooter}
                        runtimeEnvironment={options.runtimeEnvironment}
                        getConsentCookie={options.getConsentCookie}
                    />
                    <ConsentSelector
                        consentState={getCookie()}
                        handleSaveClick={handleSaveClick("customize")}
                        handleCancelClick={handleCancelClick("cancel")}
                        handleCheckboxToggle={handleCheckboxToggle}
                        localizedText={localizedText.consentSelector}
                        darkModeEnabled={options.hasConsoleNavFooter}
                        runtimeEnvironment={options.runtimeEnvironment}
                        getConsentCookie={options.getConsentCookie}
                    />
                    <CcbaSelector
                        runtimeEnvironment={options.runtimeEnvironment}
                        isRtl={isRtl}
                        content={localizedText.ccbaSelector}
                        darkModeEnabled={options.hasConsoleNavFooter}
                        getConsentCookie={options.getConsentCookie}
                        handleSaveClick={handleCcbaSaveClick("ccbaPreferenceSaved")}
                        handleCancelClick={handleCcbaCancelClick("ccbaModalDismissed")}
                    />
                    <ErrorMessageModal
                        darkModeEnabled={options.hasConsoleNavFooter}
                        handleDismissClick={handleDismissClick}
                        localizedText={localizedText.errorMessage}
                    />
                </div>
            </div>
        );
    });

    return {
        showConsentSelector(source: LogSources | undefined) {
            domReady(() => {
                showConsentSelector(source);
            })
        },
        showCcbaPreferenceModal(source: LogSources | undefined) {
            domReady(() => {
                showCcbaPreferenceModal(source);
            })
        },
        showBanner(andThen: () => void) {
            domReady(() => {
                showBanner();
                andThen();
            });
        },
        showErrorMessage() {
            domReady(() => {
                showErrorMessage();
            })
        }
    };
}

export default {
    createShortbreadUi(options: ShortbreadUiOptions) {
        // Publish init metric with sample rate (10%).
        const randomNum = Math.random();
        if (randomNum < 0.1) {
            const { log, domain } = options;
            log("info")("uiMounted", { domain });
        }
        return renderUi(options);
    },
};
