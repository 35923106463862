export namespace JSX {
    export type Element<T, P> = { type: T, props: P, children: Child[] };
    export type Function<T, P> = (type: T, props: P) => Element<T, P>;
    export type Type = string | Element<any, any> | Function<any, any>;
    export type Props = { [key: string]: any } | null;
    export type Child = Element<any, any> | string | number | null;
}

const eventProps = {
    onclick: 'click',
    onblur: 'blur',
    onfocus: 'focus',
    onchange: 'change',
    onkeydown: 'keydown',
    onkeyup: 'keyup',
    onkeypress: 'keypress'
}

const aliasedProps = {
    className: 'class',
    tabIndex: 'tabindex',
    htmlFor: 'for'
}

export function act(type: JSX.Type, props: JSX.Props, ...children: JSX.Child[]): JSX.Element<JSX.Type, JSX.Props> {
    if (typeof type === "string") {
        return {
            type,
            props,
            children
        };
    } else if (typeof type === "function") {
        return type({ ...props, children }, children);
    } else if (type.type) {
        /**
         * This is a JSX Component, pass up the underlying tag and merge the properties
         */
        return {
            type: type.type,
            props: {...type.props, ...props},
            children
        };
    }
    throw Error(`Unsupported tag type ${type}`);
}

function isInSvg(parent) {
    if (!parent) {
        return false;
    }
    if (parent.tagName === "svg") {
        return true;
    }
    return isInSvg(parent.parentNode);
}

function createElement(d: Document, parent: Element, type: JSX.Type, props: JSX.Props): Element {
    let element;
    if (type === "svg" || isInSvg(parent)) {
        element = d.createElementNS("http://www.w3.org/2000/svg", type as string);
    } else if (typeof type === "string") {
        element = d.createElement(type);
    } else {
        throw Error(`Unrecognized type ${type} (${typeof type})`);
    }
    renderAttributes(element, props);
    return element;
}

function renderAttributes(element: HTMLElement, props: JSX.Props)  {
    if (props === null) {
        return;
    }
    Object.keys(props).forEach(attribute => {
        if (eventProps[attribute.toLowerCase()] !== undefined) {
            element.addEventListener(eventProps[attribute.toLowerCase()], props[attribute]);
        } else if (attribute === "style") {
            (Object.keys(props.style) || []).forEach(s => {
                element.style[s] = props.style[s];
            })
        } else if (attribute === "events") {
            const events = props.events;
            Object.keys(events).forEach(k => {
                element.addEventListener(eventProps[k.toLowerCase()], events[k]);
            });
        } else {
            const value = props[attribute];
            if (value !== undefined) {
                element.setAttribute(aliasedProps[attribute] || attribute, value);
            }
        }
    });
}

/** Render Virtual DOM to the real DOM */
export function render<T extends JSX.Type, P extends JSX.Props>(parent: Element, jsxDescription: JSX.Element<T, P>, d = document, renderChild?: boolean) {
    const jsxDescriptions = Array.isArray(jsxDescription) ? jsxDescription : [jsxDescription];
    let firstRenderedElement: Element | null = null;

    for (const jsxDescription of jsxDescriptions) {
        const { type, props, children } = jsxDescription;
        const node = createElement(d, parent, type, props);

        if (children && children.length > 0) {
            children.flat(Infinity).forEach(c => {
                if (c === null || c === undefined) {
                    return;
                } else if (typeof c === "string") {
                    node.appendChild(d.createTextNode(c));
                } else if (typeof c === "number") {
                    node.appendChild(d.createTextNode(children.toString()));
                } else if (c !== null) {
                    render(node, c, d, true);
                } else {
                    throw Error(`Unsupported child type ${c}`);
                }
            });
        }

        const rendered = renderChild ? parent.appendChild(node) : parent.insertBefore(node, parent.firstChild);
        if (!firstRenderedElement) {
            firstRenderedElement = rendered
        }
    }

    return firstRenderedElement;
}
