import "./icon-open.scss";
import {act} from "../../jsx-act";

interface Props {
  ariaLabel: string
  size: string
}

export default ({ ariaLabel }: Props) => {
  return (
    <span aria-label={ariaLabel} role="img" class="awsccc-u-i-open-c">
      <svg class="awsccc-u-i-open" viewBox="0 0 16 16" focusable="false" aria-hidden="true">
        <path class="awsccc-stroke-linecap-square" d="M10 2h4v4"></path>
        <path d="M6 10l8-8"></path>
        <path class="awsccc-stroke-linejoin-round" d="M14 9.048V14H2V2h5"></path>
      </svg>
    </span>
  )
}
