import { act, JSX } from "../../jsx-act";
import './column-layout.scss'

interface ColumnLayoutProps extends Record<string, unknown> {
  children?: JSX.Child[]
  columns?: number;
  borders?: 'horizontal'
}

export default ({ children = [], columns = 1, borders = 'horizontal', ...props }: ColumnLayoutProps) => {
  if (columns !== 1) {
    throw new Error('Multiple columns not implemented')
  }

  return (
    <div {...props} class={`awsccc-u-cl ${props.class || ''}`}>
      {children.map(child => (
        <div class="awsccc-u-cl-c">{child}</div>
      ))}
    </div>
  )
}
