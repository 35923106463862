import { JSX, act } from '../../jsx-act';
import IconOpen from './icon-open';
import './link.scss';

interface LinkProps {
  href: string;
  children?: JSX.Child;
  target?: string;
  rel?: string;
  external?: boolean;
}

export default ({ children, href, target, rel, external = false, ...props }: LinkProps) => {
  return (
    <a class="awsccc-u-link" href={href} target={target} rel={rel} {...props}>
      <span>{children}</span>
      {external ? <IconOpen ariaLabel='' size='m' /> : null}
    </a>
  )
}
