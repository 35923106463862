import CookieDefinitions from '../../definitions/cookie-definitions';
import UXDefinitions from '../../definitions/ux-definitions';
import { act } from "../../jsx-act";
import Checkbox from '../utilities/icon-checkbox';
import "./consent-section.scss";
import HandleCheckboxToggle = UXDefinitions.HandleCheckboxToggle;

interface ConsentSectionProps {
    category: CookieDefinitions.CookieCategory,
    content: UXDefinitions.ConsentSelectorSection,
    isChecked: boolean,
    isDisabled?: boolean,
    handleCheckboxToggle: HandleCheckboxToggle,
    localizedLabelText: string
}
export default ({category, content, isDisabled, handleCheckboxToggle, localizedLabelText, isChecked}: ConsentSectionProps) => {

    return (
        <div data-category={category} class="awsccc-cs-s-container">
            {isDisabled
                    ? <h3 class="awsccc-cs-s-title">{content.title}</h3>
                    :
                <h3 class="awsccc-cs-s-title" onClick={(event) => handleCheckboxToggle({ category, event: {target: event.currentTarget}})}>{content.title}</h3>
            }
            <div class="awsccc-cs-s-text">
                <p class="awsccc-cs-s-paragraph">{content.paragraph}</p>
            </div>
            {isDisabled
                    ? <div class="awsccc-cs-s-action"/>
                    : <div class="awsccc-cs-s-action">
                        <Checkbox
                            id={category}
                            // events={{ onclick: (event) => handleCheckboxToggle({ event, category }) }}
                            isChecked={isChecked}
                            localizedDescription={content["checkbox-description"]}
                            localizedLabelText={localizedLabelText}
                            category={category}
                            handleCheckboxToggle={handleCheckboxToggle}
                        />
                    </div>
                }
        </div>
    )
}
