import {DEFAULT_LOGGER, Log} from "./logger";

export const DEFAULT_GEOLOCATION_URL = "https://prod.tools.shortbread.aws.dev/1x1.png";
export const DEFAULT_CONSOLE_INTEGRATION_GEOLOCATION_URL = "https://prod.tools.shortbread.analytics.console.aws.a2z.com/ping";
export const QUERY_PARAM_KEY = "awsccc";

export const timestampUrl = (url: string): string => {
    if (url.indexOf("?") !== -1)  {
        const splittedUrl = url.split("?");
        // if url has params or if it has params AND hash values, insert our k-v pair immediately after the '?'
        url = splittedUrl[0] + `?${QUERY_PARAM_KEY}=${Date.now()}&` + splittedUrl[1]
    } else if (url.indexOf("#") !== -1) {
        const splittedUrl = url.split("#");
        // if url only has hash values, insert k-v pair before the '#'
        url = splittedUrl[0] + `?${QUERY_PARAM_KEY}=${Date.now()}#` + splittedUrl[1]
    } else {
        // if no params or hash values are found, append k-v pair to the end
        return (url + `?${QUERY_PARAM_KEY}=${Date.now()}`)
    }
    return url
}

export const queryGeolocationByHttpGetRequest =
    (hasConsoleNavFooter = false, url: string = DEFAULT_GEOLOCATION_URL, timeoutMillis = 5000, defaultLogger: Log = DEFAULT_LOGGER): GeolocationQuery => {
        function logLatency(log: Log, latency: number, region: string, message: string, status: number) {
            log("info")("geolocationLatency", {
                metric: latency,
                region,
                detail: message,
                url,
                status: status
            });
        }

        return (geolocatedIn: GeolocationQueryResultCallback, log: Log = defaultLogger || DEFAULT_LOGGER) => {
            // Always suppress the banner for domains that end with ".cn"
            if (window.location.hostname.endsWith('.cn')) {
                return geolocatedIn("NON-EU")
            }
            const geoServiceStart = Date.now();
            const xhr = new XMLHttpRequest();
            let region: GeolocationRegion = "EU"; // default assumption
            let geoRespStatus = 200; // default assumption is 200 (in-EU)
            if (hasConsoleNavFooter) xhr.overrideMimeType("application/json");
            xhr.addEventListener("load", () => {
                geoRespStatus = xhr.status;
                if (!hasConsoleNavFooter || geoRespStatus === 304) {
                    // non-console integration or got 304
                    region = geoRespStatus === 403 ? "NON-EU" : "EU";
                } else {
                    // handle response body returned from DEFAULT_CONSOLE_INTEGRATION_GEOLOCATION_URL
                    try {
                        let geoRespBody = JSON.parse(xhr.response);
                        geoRespStatus = geoRespBody.status;
                        if (geoRespStatus === 403) region = "NON-EU";
                    } catch (e) {
                        log("error")("geolocationResponseError", { url, detail: "Failed to Parse the Received Geolocation Response" });
                    }
                }
                logLatency(log, Date.now() - geoServiceStart, region, "Geolocation Response Received", geoRespStatus);
                geolocatedIn(region);
            });
            xhr.addEventListener("timeout", () => {
                const region = "EU";
                geolocatedIn("EU")
                const message = "Geolocation Request Timed out";
                logLatency(log, timeoutMillis, region, message, xhr.status); // use default xhr (0) if request times out
                log("error")("geolocationRequestTimeout", { url, timeoutSetting: timeoutMillis, detail: message });
            });
            xhr.open("GET", timestampUrl(url));
            // setting the timeout after the .open() to fix InvalidStateError on IE 11
            // based on the suggestion here: https://github.com/stephanebachelier/superapi/issues/5
            xhr.timeout = timeoutMillis;
            xhr.send();
        };
}
export type GeolocationRegion = "EU" | "NON-EU";
export type GeolocationQueryResultCallback = (region: GeolocationRegion) => void;
export type GeolocationQuery = (geolocatedIn: GeolocationQueryResultCallback, log?: Log) => void;
export default queryGeolocationByHttpGetRequest;
