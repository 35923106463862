import { DEFAULT_COOKIE } from "../../constants/cookie-constants";
import { BANNER_ACCEPT_BTN_ID, BANNER_CUSTOMIZE_BTN_ID, BANNER_DECLINE_BTN_ID, BANNER_ID } from "../../constants/ux-constants";
import { getConsentCookie as getConsentCookieCore } from "../../cookie";
import CookieDefinitions from "../../definitions/cookie-definitions";
import UXDefinitions from "../../definitions/ux-definitions";
import { act } from "../../jsx-act";
import Button from "../utilities/button";
import './consent-banner.scss';

interface ConsentBannerProps {
    showConsentSelector:  UXDefinitions.HandleShowConsent,
    handleSaveClick: UXDefinitions.HandleSaveClick,
    handleDeclineClick: UXDefinitions.HandleSaveClick,
    localizedText: UXDefinitions.ConsentBannerLocalization,
    hasConsoleNavFooter?: boolean,
    runtimeEnvironment?: UXDefinitions.RuntimeEnvironment,
    getConsentCookie: () => ReturnType<typeof getConsentCookieCore>,
}
export default ({showConsentSelector, handleSaveClick, handleDeclineClick, localizedText, getConsentCookie, hasConsoleNavFooter = false, runtimeEnvironment = 'default'}: ConsentBannerProps) => {
    const cookie = getConsentCookie() || DEFAULT_COOKIE
    const AcceptAllCookie: CookieDefinitions.ConsentCookie = {
        essential: true,
        functional: true,
        performance: true,
        advertising: true,
        ccba: cookie['ccba']
    }

    const Decline: CookieDefinitions.ConsentCookie = {
        essential: true,
        functional: false,
        performance: false,
        advertising: false,
        ccba: cookie['ccba']
    }

    //accounts of console nav's footer by using a global css class name from console
    //adds some bottom offset to the fixed element also reduces z-index from covering console nav footer's language selector
    let bannerContainerClass = hasConsoleNavFooter === true ? "awsccc-tab-helper awsc-bot-above-f-imp" :  "awsccc-tab-helper";

    return (
        <div data-id={BANNER_ID} style={{display: 'none'}}>
            <div id="awsccc-cb-c" data-id="awsccc-cb-tabstart" class={bannerContainerClass} tabIndex={-1}>
                <div id="awsccc-cb-content">
                    <div id="awsccc-cb-text-section">
                        <h2 id="awsccc-cb-title">{localizedText.title}</h2>
                        <p id="awsccc-cb-text">{runtimeEnvironment === 'mobile' ? localizedText['paragraph-mobile'] : localizedText.paragraph}</p>
                    </div>
                    <div id="awsccc-cb-actions">
                    <div id="awsccc-cb-buttons">
                            <Button
                                dataId={BANNER_ACCEPT_BTN_ID}
                                variant="primary"
                                text={localizedText["button-accept"]}
                                events={{onclick: () => handleSaveClick(AcceptAllCookie, "consentBanner")}}
                                props={{"aria-label": localizedText["button-accept-aria-label"]}}/>
                            <Button
                                dataId={BANNER_DECLINE_BTN_ID}
                                variant="secondary"
                                text={localizedText["button-decline"]}
                                events={{onclick: () => handleDeclineClick(Decline, "consentBanner")}}
                                props={{"aria-label": localizedText["button-decline-aria-label"]}}/>
                            <Button
                                dataId={BANNER_CUSTOMIZE_BTN_ID}
                                variant="secondary"
                                text={localizedText["button-customize"]}
                                events={{onclick: () => showConsentSelector("consentBanner")}}
                                props={{"aria-label": localizedText["button-customize-aria-label"]}}/>
                        </div>
                        </div>
                </div>
            </div>
        </div>
    )
};
