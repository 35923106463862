import { CUSTOMIZE_ID, TABTRAP_ID } from "../../constants/ux-constants";
import { getConsentCookie as getConsentCookieCore } from "../../cookie";
import CookieDefinitions from "../../definitions/cookie-definitions";
import UXDefinitions from "../../definitions/ux-definitions";
import { act } from "../../jsx-act";
import ColumnLayout from "../utilities/column-layout";
import Modal from "../utilities/modal";
import ConsentFooter from "./consent-footer";
import ConsentInfo from "./consent-info";
import ConsentLegal from "./consent-legal";
import ConsentSection from "./consent-section";

interface ConsentProps {
  handleSaveClick: UXDefinitions.HandleSaveClick;
  handleCancelClick: UXDefinitions.HandleCancelClick;
  handleCheckboxToggle: UXDefinitions.HandleCheckboxToggle;
  localizedText: UXDefinitions.ConsentSelectorLocalization;
  consentState: CookieDefinitions.ConsentCookie;
  darkModeEnabled?: boolean;
  runtimeEnvironment?: UXDefinitions.RuntimeEnvironment;
  getConsentCookie: () => ReturnType<typeof getConsentCookieCore>;
}

export default ({
  handleSaveClick,
  handleCancelClick,
  handleCheckboxToggle,
  localizedText,
  consentState,
  getConsentCookie,
  darkModeEnabled = false,
  runtimeEnvironment = "default",
}: ConsentProps) => {
  return (
    <Modal
      id={CUSTOMIZE_ID}
      size="large"
      onDismiss={handleCancelClick}
      darkModeEnabled={darkModeEnabled}
      header={<span id="awsccc-cs-title">{localizedText.header}</span>}
      footer={
        <ConsentFooter
          handleSaveClick={handleSaveClick}
          handleCancelClick={handleCancelClick}
          localizedText={localizedText}
          getConsentCookie={getConsentCookie}
        />
      }
    >
      <ColumnLayout columns={1} borders="horizontal">
        <ConsentInfo localizedText={localizedText.intro} />
        <ConsentSection
          category="essential"
          content={localizedText["section-essential"]}
          isDisabled
          isChecked={consentState.essential}
          handleCheckboxToggle={handleCheckboxToggle}
          localizedLabelText={localizedText["checkbox-label"]}
        />
        <ConsentSection
          category="performance"
          content={localizedText["section-performance"]}
          isChecked={consentState.performance}
          handleCheckboxToggle={handleCheckboxToggle}
          localizedLabelText={localizedText["checkbox-label"]}
        />
        <ConsentSection
          category="functional"
          content={localizedText["section-functional"]}
          isChecked={consentState.functional}
          handleCheckboxToggle={handleCheckboxToggle}
          localizedLabelText={localizedText["checkbox-label"]}
        />
        <ConsentSection
          category="advertising"
          content={localizedText["section-advertising"]}
          isChecked={consentState.advertising}
          handleCheckboxToggle={handleCheckboxToggle}
          localizedLabelText={localizedText["checkbox-label"]}
        />
        <ConsentLegal
          localizedText={
            runtimeEnvironment === "mobile"
              ? localizedText["footer-mobile"]
              : localizedText.footer
          }
        />
      </ColumnLayout>
    </Modal>
  );
};
