import { JSX, act } from '../../jsx-act';
import "./consent-legal.scss";

interface ConsentLegalProps {
    localizedText: string|JSX.Element<JSX.Type, JSX.Props>
}
export default ({localizedText}: ConsentLegalProps) => {
    return (
    <div id="awsccc-cs-l-container">
        <p>
            {localizedText}
        </p>
    </div>
    )
}
