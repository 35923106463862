import { act, JSX } from "../../jsx-act"
import './form-field.scss'

interface FormFieldProps {
  children?: JSX.Child;
  label: string;
}

export default ({ label, children }: FormFieldProps) => {
  return (
    <div class="awsccc-u-field">
      <label>
        <span class="awsccc-u-field-label">{label}</span>
        <div class="awsccc-u-field-input">
          {children}
        </div>
      </label>
    </div>
  )
}
