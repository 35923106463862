import { act } from '../../jsx-act'
import './radio-group.scss'
import SpaceBetween from './space-between';

interface RadioGroupProps {
  name: string;
  onChange?: (event: CustomEvent<{ value: string }>) => void;
  initialValue?: string
  items?: {
    value: string;
    label?: string
  }[]
}

export default ({ name, items = [], initialValue, onChange = () => null }: RadioGroupProps) => {
  let value = initialValue

  const handleChange = (newValue: string) => {
    value = newValue;
    onChange(new CustomEvent('change', { detail: { value: newValue }}))
  }

  return (
    <div class="awsccc-u-rg" role='radiogroup'>
      <SpaceBetween direction='vertical' size='xxs'>
        {items.map(item => (
          <label>
            <input
              type="radio"
              name={`awsccc-u-rg-${name}`}
              value={item.value}
              checked={item.value === value ? true : undefined}
              onChange={(e) => e.target.checked ? handleChange(item.value) : null}
            />
            <span class="awsccc-u-rg-radio" />
            <span>{item.label || item.value}</span>
          </label>
        ))}
      </SpaceBetween>
    </div>
  )
}
