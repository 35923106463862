import { JSX, act } from '../../jsx-act';
import "./consent-info.scss";

interface ConsentInfoProps {
    localizedText: string|JSX.Element<JSX.Type, JSX.Props>
}
export default ({localizedText}: ConsentInfoProps) => {
    return (
        <div id="awsccc-cs-i-container">
            <span>
                {localizedText}
            </span>
        </div>
    )
}
