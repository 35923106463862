import CookieDefinitions from "../definitions/cookie-definitions";
import UXDefinitions from "../definitions/ux-definitions";

export const COOKIE_VERSION = "1";

export const DEFAULT_COOKIE_AGE = 31536000;

export const DEFAULT_COOKIE: CookieDefinitions.ConsentCookie = {
  essential: true,
  functional: false,
  performance: false,
  advertising: false,
  ccba: true,
};

export const ALL_ALLOWED: CookieDefinitions.ConsentCookie = {
  essential: true,
  functional: true,
  performance: true,
  advertising: true,
  ccba: true,
};

export const DEFAULT_LANGUAGE: UXDefinitions.LanguageCodesNormalized = "en-us";

export const DEFAULT_DOMAIN = ".aws.amazon.com";

export const DEFAULT_TANGERINEBOX_DEV_DOMAIN = ".aws-dev.amazon.com";

export const CONSENT_COOKIE_CHANGED_EVENT = "cookie-consent-changed";
